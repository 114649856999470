/* routes */
.fade-exit {
    opacity: 1;
  }
  .fade-enter,
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  /* spinner transition */
  .spinner-enter {
    opacity: 0;
  }
  .spinner-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .spinner-exit {
    opacity: 1;
  }
  .spinner-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  /* generic */
  /* list */
  /* item */
  
  .generic-item-enter {
    opacity: 0;
  }
  .generic-item-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .generic-item-exit {
    opacity: 1;
  }
  .generic-item-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  /*grid generator*/
  .grid-generator-enter {
    opacity: 0;
  }
  .grid-generator-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .grid-generator-exit {
    opacity: 1;
  }
  .grid-generator-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }