@import 'assets/scss/style.scss';
.swiper-pagination {
  position: absolute !important;
}
.scroll-top i,
.scroll-top svg {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn--white,
.ht-btn,
.ht-btn-md {
  background-color: $btn-bg !important;
  background: linear-gradient($btn-bg, 20%, $btn-bg-degraded);
  border: none !important;
  color: $body-text-color !important;
}
/* .btn--white:hover, .btn--white:focus {
  color: $body-text-color!important;
}*/
.preview-header-inner .header__actions--preview .ht-btn {
  color: $body-text-color !important;
  background: linear-gradient($btn-bg, 20%, $btn-bg-degraded);
}
.breadcrumb-title {
  color: $white !important;
}
.breadcrumb-item {
  color: $theme-color-sub--heading !important;
}
.breadcrumb-list li.active {
  color: $theme-color--green !important;
}
.mobile-menu-trigger {
  background-color: $white !important;
}
.ht-timeline.style-01 .timeline-info .content-body {
  width: 500px !important;
}
.mt-tl {
  margin-top: 10rem;
}
.header__logo > a p {
  color: $white !important;
}
.header-area .is-sticky p {
  display: none!important;
}
.header-area .is-sticky img {
  width: 160px;
}
.header__logo > a img {
  width: 220px;
}
@media only screen and (max-width: 1235px) {
  .header__logo > a p {
    display: none!important;
  }
  .header__logo > a img {
    width: 160px;
  }
}
